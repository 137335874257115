import axios from 'axios';
import { refreshToken } from './refreshToken';

export async function getCall(url) {
    try {
      await refreshToken();

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/vnd.api+json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('getCall Error', error.message);
      throw error
    }
  }