import { getCall } from "../auth/getCall";
import { refreshToken } from "../auth/refreshToken";

export async function getOrders() {
  try {
    await refreshToken();

    const output = await getCall(
      "https://exelab.commercelayer.io/api/orders"
    );

    return output;
  } catch (error) {
    console.error('getOrders Error', error.message);
      throw error
  }
}

export async function getOrderInfo(orderId) {
  try {
    await refreshToken();

    const output = await getCall(
      "https://exelab.commercelayer.io/api/orders/" + orderId + "?include=customer,shipping_address,billing_address,line_items"
    );

    return output;
  } catch (error) {
    console.error('getOrders Error', error.message);
      throw error
  }
}
  