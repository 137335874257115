import axios from 'axios';

export async function refreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  const clientId = 'ZjlI42O6X8XCft6E0SZow1TfpByYqminACxzWtEdKYM';
  const clientSecret = 'rKLYQz5qdJPRE2rzTr1_TR9aD6JTmiGMSHDfQT4VOTY';

  if (refreshToken) {
    try {
      const response = await axios.post(
        'https://exelab.commercelayer.io/oauth/token',
        {
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: clientId,
          client_secret: clientSecret,
        }
      );

      localStorage.setItem('accessToken', response.data.access_token);
    } catch (error) {
        console.error('refreshToken Error', error.message);
        throw error
    }
  }
}
