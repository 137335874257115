import { clientId, originUrl, scope } from "../api/config";

const LoginPage = () => {
  const requestCommerceLayerAuth = () => {
    const redirectUri = `${originUrl}/authenticate`;
    window.location.href = `https://dashboard.commercelayer.io/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;
  };

  return (
    <div>
      <h2>Login Page</h2>
      <button onClick={requestCommerceLayerAuth}>
        Login con CommerceLayer
      </button>
    </div>
  );
};

export default LoginPage;
