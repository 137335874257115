import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessToken } from "../api/auth/getAccessToken";

const CommerceLayerAuthenticate = ({ onLogin }) => {
  const [code, setCode] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getParameterFromQueryString = (name) => {
      const params = new URLSearchParams(location.search);
      return params.get(name);
    };

    const _code = getParameterFromQueryString("code");
    if (_code) {
      console.log("Codice ricevuto", _code);
      setCode(_code);
    }
  }, [location]);

  useEffect(() => {
    if (code) {
      console.log(code);
      getAccessToken(code).then((token) => {
        localStorage.setItem("accessToken", token.access_token);
        localStorage.setItem("refreshToken", token.refresh_token);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            id: token.owner_id,
            type: token.owner_type,
          })
        );
        navigate("/");
        onLogin();
      });
    }
  }, [code, onLogin, navigate]);

  return (
    <div>
      <h2>Benvenuto... verrai rediretto sulla pagina iniziale</h2>
    </div>
  );
};

export default CommerceLayerAuthenticate;
