import {  Route, Routes } from "react-router-dom";
import OrderDetail from "./OrderPage/OrderDetail";
import OrderList from "./OrderPage/OrderList";

const OrderPage = ({ onLoading, onError }) => {
  return (
    <div className="exelab-orderpage">
      <Routes>
            <Route
              path="/"
              element={
                <OrderList
                  onLoading={(value) => onLoading(value)}
                  onError={(value) => onError(value)}
                />
              }
            />
            <Route
              path="/:id"
              element={
                <OrderDetail
                  onLoading={(value) => onLoading(value)}
                  onError={(value) => onError(value)}
                />
              }
            />
            </Routes> 
    </div>
  );
};

export default OrderPage;
