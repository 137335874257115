import { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import OrderPage from "../components/Main/OrderPage";
import CatalogPage from "../components/Main/CatalogPage";

const MainPage = () => {
  const [page, setPage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);

  function handleLogout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/";
  }

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    setUser(JSON.parse(userInfo));
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  function handleError(error) {
    setError(error.message);
    setTimeout(() => {
      setError(null);
    }, 5000);
  }

  function handleListSkus() {
    setPage("products");
  }

  function handleListOrders() {
    setPage("orders");
  }

  return (
    <div className={"pocwrapper " + (isLoading ? "loading" : "")}>
      <div className="sidebar">
        <div className="sidebarHeader"></div>
        <div className="sidebarButtons">
          <Link
            className={page === "products" ? "active" : ""}
            onClick={handleListSkus}
            to="/products/"
          >
            Catalogo
          </Link>
          <Link
            className={page === "orders" ? "active" : ""}
            onClick={handleListOrders}
            to="/orders/"
          >
            Ordini
          </Link>
        </div>
      </div>
      <div className="mainPage">
        <div className="mainHeader">
          <div className="mainHeader-title">Main Page</div>
          <div className="mainHeader-subTitle">
            {page === "products" && <div>Catalogo</div>}
            {page === "orders" && <div>Ordini</div>}
          </div>
          <div className="mainHeader-logOut" onClick={() => handleLogout()}>
            Logout (UserId: {user?.id})
          </div>
        </div>
        <div className="mainContent">
          <Routes>
            <Route
              path="/orders/*"
              element={
                <OrderPage
                  onLoading={(value) => setIsLoading(value)}
                  onError={(value) => handleError(value)}
                />
              }
            /> 
            <Route
              path="/products/*"
              element={
                <CatalogPage
                  onLoading={(value) => setIsLoading(value)}
                  onError={(value) => handleError(value)}
                />
              }
            />
          </Routes>
        </div>
      </div>
      {error && <div className="pageError">{error}</div>}
    </div>
  );
};

export default MainPage;
