import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getOrders } from "../../../api/orders/getOrders";

const OrderList = ({ onLoading, onError }) => {
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    if (!orders) {
      const fetchData = async () => {
        try {
          return await getOrders();
        } catch (error) {
          onError(error.message);
        }
      };

      onLoading(true);
      fetchData().then((orders) => {
        setOrders(orders);
        onLoading(false);
      });
    }
  }, [orders, onLoading, onError]);

  return (
    <div className="exelab-listorders">
      <ul>
        <li>
          <div className="exelab-orderdetail exelab-orderdetail-header">
            <div className="exelab-orderdetail-ordernumber">Numero Ordine</div>
            <div className="exelab-orderdetail-status">Stato</div>
            <div className="exelab-orderdetail-payment_status">
              Stato Pagamento
            </div>
            <div className="exelab-orderdetail-guest">Guest</div>
            <div className="exelab-orderdetail-customer_email">
              Customer Email
            </div>
          </div>
        </li>
        {orders?.data.map((order) => (
          <li key={order.id}>
            <div className="exelab-orderdetail">
              <div className="exelab-orderdetail-ordernumber">
                <Link to={`/orders/${order.id}`}>
                  {order.attributes.number}
                </Link>
              </div>
              <div className="exelab-orderdetail-status">
                {order.attributes.status}
              </div>
              <div className="exelab-orderdetail-payment_status">
                {order.attributes.payment_status}
              </div>
              <div className="exelab-orderdetail-guest">
                {order.attributes.guest ? "Yes" : "No"}
              </div>
              <div className="exelab-orderdetail-customer_email">
                {order.attributes.customer_email}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderList;
