import { getCall } from "../auth/getCall";
import { refreshToken } from "../auth/refreshToken";

export async function getSkus() {
  try {
    await refreshToken();

    const response = await getCall(
      "https://exelab.commercelayer.io/api/skus?include=prices"
    );
    const includedData = response.included || [];

    const output = response.data.map((sku) => {
      const price = includedData.find(
        (item) =>
          item.type === "prices" &&
          item.attributes.sku_code === sku.attributes.code
      );
      return {
        id: sku.id,
        attributes: sku.attributes,
        price: price ? price.attributes : null,
      };
    });

    return output;
  } catch (error) {
    console.error('getSkus Error', error.message);
      throw error
  }
}
