import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import CommerceLayerAuthenticate from "./components/CommerceLayerAuthenticate";
import "./App.css";

function Root() {
  const [isLogged, setIsLogged] = useState(false);

  const handleLogin = () => {
    setIsLogged(true);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsLogged(true);
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/authenticate"
        element={<CommerceLayerAuthenticate onLogin={handleLogin} />}
      />
      <Route
        path="/*"
        element={
          isLogged ? <MainPage /> : <LoginPage />
        }
      />
    </Routes>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <Root />
      </Router>
    </div>
  );
}

export default App;
