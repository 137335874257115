import { useEffect, useState } from "react";
import { getSkus } from "../../api/products/getSkus";

const CatalogPage = ({ onLoading, onError }) => {
  const [skus, setSkus] = useState(null);

  useEffect(() => {
    if (!skus) {
      const fetchData = async () => {
        try {
          return await getSkus();
        } catch (error) {
          onError(error.message)
        }
      };

      onLoading(true);
      fetchData().then((skus) => {
        setSkus(skus);
        onLoading(false);
      });
    }
  }, [ skus, onLoading, onError]) 


  return (
    <div className="exelab-listskus">
      <ul>
        {skus?.map((sku) => (
          <li key={sku.id}>
            <div className="exelab-skudetail">
              <div className="exelab-skudetail-code">{sku.attributes.code}</div>
              <div className="exelab-skudetail-name">{sku.attributes.name}</div>
              <div className="exelab-skudetail-description">
                {sku.attributes.description}
              </div>
              { sku.price && (
                <div className="exelab-skudetail-price">
                    {sku.price.amount_cents / 100} {sku.price.currency_code}
                </div>
              ) }
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CatalogPage;
