import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrderInfo } from "../../../api/orders/getOrders";

function OrderDetail({ onLoading, onError }) {
  const [order, setOrder] = useState(null);

  const orderId = useParams().id;

  useEffect(() => {
    if (!order) {
      const fetchData = async () => {
        try {
          return await getOrderInfo(orderId);
        } catch (error) {
          onError(error.message);
        }
      };

      onLoading(true);
      fetchData().then((order) => {
        setOrder(order);
        onLoading(false);
      });
    }
  }, [order, onLoading, onError, orderId]);
  return (
    <div className="exelab-orderdetailpage">
      <h2>Order Detail {order ? order.data.attributes.number : orderId}</h2>
      {order && (
        <div className="exelab-orderdetail-attributes">
          {Object.keys(order.data.attributes).map((key) => (
            <div key={key} className={"exelab-orderdetail-attribute-" + key}>
              <div className="exelab-orderdetail-attribute-key">{key}</div>
              <div className="exelab-orderdetail-attribute-value">
                {JSON.stringify(order.data.attributes[key])}
              </div>
            </div>
          ))}
        </div>
      )}
      {order && (
        <div className="exelab-orderdetail-includedlist">
          {order.included.map((includeInfo) => {
            return (
              <div className="exelab-orderdetail-included">
                <h3>{includeInfo.type}</h3>
                <div className="exelab-orderdetail-attributes">
                  {Object.keys(includeInfo.attributes).map((key) => (
                    <div
                      key={key}
                      className={"exelab-orderdetail-attribute-" + key}
                    >
                      <div className="exelab-orderdetail-attribute-key">
                        {key}
                      </div>
                      <div className="exelab-orderdetail-attribute-value">
                        {JSON.stringify(includeInfo.attributes[key])}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default OrderDetail;
