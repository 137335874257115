import axios from "axios";
import { clientId, clientSecret, originUrl, scope } from "../config";

export async function getAccessToken(authorizationCode) {
  try {
    const response = await axios.post(
      `https://exelab.commercelayer.io/oauth/token`,
      {
        grant_type: "authorization_code",
        code: authorizationCode,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: `${originUrl}/authenticate`,
        scope: scope,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Errore durante l'ottenimento dell'access token:",
      error.message
    );
    throw error;
  }
}
